// _Login
import { getLogoutRedirectUrl } from "@app/_Login/components/Auth/utils";

// toolkit
import Button from "@app/toolkit/Form/Button";

// styles
import s from "./styles.module.scss";

const AccessDenied = () => {
  const handleLogout = () => {
    window.location.href = getLogoutRedirectUrl();
  };

  return (
    <>
      <div className={s.accessDenied}>
        <h1>Access Denied</h1>
        <p>You do not have permission to access this application.</p>
        <p>
          Please contact your administrator if you believe this is an error.
        </p>
        <Button onClick={handleLogout}>Logout</Button>
      </div>
    </>
  );
};

export default AccessDenied;
