export const RESET_ADMINISTRATION_STATE =
  "ADMINISTRATION::RESET_ADMINISTRATION_STATE";

/** ****************** USERS *************************** */
export const LOAD_USERS = "ADMINISTRATION::LOAD_USERS";
export const UPDATE_USER = "ADMINISTRATION::UPDATE_USER";
export const CREATE_USER = "ADMINISTRATION::CREATE_USER";
export const UPDATE_USER_STATUS = "ADMINISTRATION::UPDATE_USER_STATUS";
export const RESEND_USER_INVITE = "ADMINISTRATION::RESEND_USER_INVITE";
export const SHOW_EDIT_USER_FORM = "ADMINISTRATION::SHOW_EDIT_USER_FORM";
export const HIDE_EDIT_USER_FORM = "ADMINISTRATION::HIDE_EDIT_USER_FORM";
export const SHOW_ADD_USER_MODAL = "ADMINISTRATION::SHOW_ADD_USER_MODAL";
export const HIDE_ADD_USER_MODAL = "ADMINISTRATION::HIDE_ADD_USER_MODAL";

/** ****************** COMPANY *************************** */
export const LOAD_COMPANY_PROFILE = "ADMINISTRATION::LOAD_COMPANY_PROFILE";
export const UPDATE_COMPANY_PROFILE = "ADMINISTRATION::UPDATE_COMPANY_PROFILE";
export const DELETE_COMPANY_PROFILE = "ADMINISTRATION::DELETE_COMPANY_PROFILE";

/** ****************** METADATA *************************** */
export const SHOW_METADATA_MODAL = "ADMINISTRATION::SHOW_METADATA_MODAL";
export const HIDE_METADATA_MODAL = "ADMINISTRATION::HIDE_METADATA_MODAL";
