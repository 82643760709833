import { useCallback, useMemo } from "react";
import {
  fetchSchedulerNotifications,
  fetchSchedulerSystemSettings,
  updateSchedulerSystemSetting as updateSchedulerSystemAPI,
} from "@app/API";
import { ApiSuccessResponse } from "@app/API/_base";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataSubmitting,
} from "@app/redux/utils";
import { useAsync } from "@app/utils/react-async-hook";
import { filter, keyBy } from "@app/utils/lodash";
import cronParser from "cron-parser";
import { isEmpty } from "@app/utils/lodash";
import { User } from "../users";
import { NOOP } from "@app/utils/helpers";

export enum SchedulerTaskName {
  NotificationSend = "NOTIFICATION.send",
  SavedSearchSend = "SAVED_SEARCH_ALERT.send",
}

export enum SchedulerEventName {
  Renew = "renew",
  SavedSearch = "saved_search",
}

export enum SchedulerObjectType {
  Document = "document",
  SavedSearch = "saved_search",
}

export enum SchedulerAlertType {
  User = "USER",
  System = "SYSTEM",
}

export interface SchedulerNotification {
  alertType?: SchedulerAlertType;
  arguments?: {
    userId?: string;
    template?: string;
    downloadCsv?: boolean;
    noticeRequiredDays?: number;
  };
  cron: string;
  executedUntilTime: string;
  iterations: number;
  iterationsCount: number;
  nextExecutionTime: string;
  lastExecutionTime?: string;
  objectId: string;
  objectType: SchedulerObjectType;
  scheduleId: string;
  taskName: SchedulerTaskName;
  eventName: SchedulerEventName;
  userId: string;
  companyId: string;
  createdDate: string;
  timezone: string;
  user: User;
}

export interface SchedulerSystemSetting {
  name: SchedulerSystemName;
  enabled: boolean;
  nextExecutionTime: string;
}

export enum SchedulerSystemName {
  DocumentRenew = "document-renew",
  DocumentTerminate = "document-terminate",
}

export enum SchedulerSystemAction {
  Enable = "ENABLE",
  Disable = "DISABLE",
}

export enum SchedulerNotificationFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
}

export const getFrequencyFromSchedule = (schedule: string) => {
  try {
    const { dayOfMonth, dayOfWeek } =
      cronParser.parseExpression(schedule).fields;
    let frequency = SchedulerNotificationFrequency.Daily;
    if (dayOfWeek.length < 8) {
      // when all 8 days of the week are listed (sunday is 0 and 7), no weekly schedule is configured
      frequency = SchedulerNotificationFrequency.Weekly;
    } else if (dayOfMonth.length < 31) {
      // when all 31 days of the month are listed, no monthly schedule is configured
      frequency = SchedulerNotificationFrequency.Monthly;
    }
    return frequency;
  } catch (e) {
    return null;
  }
};

export const getNextCronRunDate = (schedule: string) => {
  try {
    return cronParser
      .parseExpression(schedule, { utc: true })
      .next()
      .toISOString();
  } catch (e) {
    return null;
  }
};

export const getDailySchedule = () => "0 0 * * *";

export const getMonthlySchedule = (daysOfMonth: number[]) => {
  if (isEmpty(daysOfMonth)) return null;
  return `0 0 ${daysOfMonth.join(",")} * *`;
};

export const getWeeklySchedule = (daysOfWeek: number[]) => {
  if (isEmpty(daysOfWeek)) return null;
  return `0 0 * * ${daysOfWeek.join(",")}`;
};

export enum ReminderFrequencyCron {
  Daily = "0 0 * * *",
  Weekly = "0 0 * * 1",
  TwiceWeekly = "0 0 * * 0,4",
  BiWeekly = "0 0 */14 * *",
  ThirtyDays = "0 0 */30 * *",
  SixtyDays = "0 0 */60 * *",
  NinetyDays = "0 0 */90 * *",
  OneTwentyDays = "0 0 */120 * *",
}

const loadNotifications = async (
  objectId?: string,
  objectType?: SchedulerObjectType
) => {
  const response = (await fetchSchedulerNotifications(
    objectId,
    objectType
  )) as ApiSuccessResponse<any>;
  return response?.data;
};

export function useSchedulerNotifications(
  objectId?: string,
  objectType?: SchedulerObjectType
): [{ schedules: SchedulerNotification[] }, DataStatus, () => void] {
  const { status, result, execute } = useAsync<{
    schedules: SchedulerNotification[];
  }>(loadNotifications, [objectId, objectType]);

  const refresh = useCallback(
    () => execute(objectId, objectType),
    [execute, objectId, objectType]
  );
  return [result, status, refresh];
}

export const useSavedSearchNotifications = (
  savedSearchId?: string,
  userId?: string
) => {
  const [schedulesResponse, scheduleStatus, refresh] =
    useSchedulerNotifications(savedSearchId, SchedulerObjectType.SavedSearch);

  const notifications = useMemo(
    () =>
      filter(
        schedulesResponse?.schedules,
        (schedulerNotification: SchedulerNotification) =>
          !userId || schedulerNotification.userId === userId
      ),
    [schedulesResponse?.schedules, userId]
  );

  const notificationsMap = useMemo(
    () => keyBy(notifications, "objectId"),
    [notifications]
  );

  const status = useMemo(
    () => ({
      isLoading: isDataLoading(scheduleStatus),
      hasError: hasDataError(scheduleStatus),
      hasLoaded: hasDataLoaded(scheduleStatus),
      isSubmitting: isDataSubmitting(scheduleStatus),
    }),
    [scheduleStatus]
  );

  return {
    notifications,
    notificationsMap,
    status,
    refresh,
  };
};

const loadSchedulerSystemSettings = async (companyId?: string) => {
  const response = (await fetchSchedulerSystemSettings(
    companyId
  )) as ApiSuccessResponse<any>;
  return response?.data;
};

const updateSchedulerSystemSetting = async (
  name: SchedulerSystemName,
  action: SchedulerSystemAction,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP,
  companyId?: string
) => {
  updateSchedulerSystemAPI({
    name,
    action,
    companyId,
  })
    .then(onSuccess)
    .catch(onFailure);
};

export function useSchedulerSystemSettings(companyId?: string): [
  { schedules: SchedulerSystemSetting[] },
  DataStatus,
  {
    update: (
      name: string,
      action: string,
      onSuccess?: () => void,
      onFailure?: () => void
    ) => void;
    refresh: () => void;
  }
] {
  const { status, result, execute } = useAsync<{
    schedules: SchedulerSystemSetting[];
  }>(loadSchedulerSystemSettings, [companyId]);

  const refresh = useCallback(() => execute(companyId), [execute, companyId]);

  const update = useCallback(
    (
      name: SchedulerSystemName,
      action: SchedulerSystemAction,
      onSuccess,
      onFailure
    ) =>
      updateSchedulerSystemSetting(
        name,
        action,
        onSuccess,
        onFailure,
        companyId
      ),
    [companyId]
  );

  const actions = useMemo(() => ({ refresh, update }), [update, refresh]);

  return [result, status, actions];
}
