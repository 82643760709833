import { useCallback, useMemo } from "react";
import { ApiSuccessResponse } from "@app/API/_base";
import { DataStatus } from "@app/redux/utils";
import { useAsync } from "@app/utils/react-async-hook";
import { ReminderFrequencyCron, SchedulerObjectType } from "../scheduler";
import { User } from "@app/entities/users";
import * as API from "@app/API";
import { useTranslation } from "@app/utils/hooks";

export enum AlertName {
  DocumentRenewal = "document-renewal",
}

export enum AlertType {
  User = "USER",
  System = "SYSTEM",
}

export interface AlertSchedule {
  nextAlert: string;
  lastAlert: string | null;
  cron: string;
  timezone: string;
  iterations: number;
}

export interface Alert {
  alertId: string;
  alertName: string;
  objectId: string;
  objectType: string;
  userId: string;
  user: User;
  schedules: AlertSchedule[];
  alertType: string;
  arguments: {
    cron?: string;
    iterations?: number;
    noticeRequiredDays?: number;
    rule?: string;
    template?: string;
  };
}

async function loadAlerts(
  objectId: string,
  objectType: SchedulerObjectType,
  alertType: AlertType,
  alertName: AlertName
) {
  const response = (await API.loadAlerts(
    objectId,
    objectType,
    alertType,
    alertName
  )) as ApiSuccessResponse<any>;
  return response?.data?.alerts ?? [];
}

export function useAlerts(
  objectId: string,
  objectType: SchedulerObjectType = SchedulerObjectType.Document,
  alertType: string = AlertType.User,
  alertName?: string
): [Alert[], DataStatus, () => void] {
  const { status, result, execute } = useAsync<Alert[]>(
    loadAlerts,
    [objectId, objectType, alertType, alertName],
    {
      setLoading: (state) => {
        return { ...state, loading: true, status: "loading" } as any; // keep previous results while loading new results
      },
    }
  );

  const refresh = useCallback(
    () => execute(objectId, objectType, alertType),
    [objectId, objectType, alertType, execute]
  );
  return [result, status, refresh];
}

export function useCompanyDocumentRemindersSettings() {
  return useAlerts(
    "*",
    SchedulerObjectType.Document,
    AlertType.System,
    AlertName.DocumentRenewal
  );
}

export function useAlertFrequencyOptions() {
  const { t } = useTranslation();
  return useMemo<Array<{ label: string; value: string }>>(
    () => [
      {
        label: t("quick-view.renewal.reminders.frequency.once"),
        value: "",
      },
      {
        label: t("quick-view.renewal.reminders.frequency.daily"),
        value: ReminderFrequencyCron.Daily,
      },
      {
        label: t("quick-view.renewal.reminders.frequency.weekly"),
        value: ReminderFrequencyCron.Weekly,
      },
      {
        label: t("quick-view.renewal.reminders.frequency.twice-weekly"),
        value: ReminderFrequencyCron.TwiceWeekly,
      },
      {
        label: t("quick-view.renewal.reminders.frequency.biweekly"),
        value: ReminderFrequencyCron.BiWeekly,
      },
      {
        label: t("quick-view.renewal.reminders.frequency.thirtydays"),
        value: ReminderFrequencyCron.ThirtyDays,
      },
      {
        label: t("quick-view.renewal.reminders.frequency.sixtydays"),
        value: ReminderFrequencyCron.SixtyDays,
      },
      {
        label: t("quick-view.renewal.reminders.frequency.ninetydays"),
        value: ReminderFrequencyCron.NinetyDays,
      },
      {
        label: t("quick-view.renewal.reminders.frequency.onetwentydays"),
        value: ReminderFrequencyCron.OneTwentyDays,
      },
    ],
    [t]
  );
}
