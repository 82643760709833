import {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { get, isNil, pick } from "@app/utils/lodash";
import { ISortInfo } from "@app/types/IPageable";
import { useDispatch, useSelector } from "react-redux";
import { IPagination } from "@app/types-business/Project";
import { getLoginUrl, getMyCompanyId } from "@app/_Login/reducers/selectors";
import { fetchLoginUrl } from "@app/_Login/actions";
import {
  cleanStateFromLocalStorage,
  isDevelopment,
  loadStateFromLocalStorage,
  saveStateInLocalStorage,
} from "./helpers";
import { OutputSelector } from "reselect";
import constants from "./constants";
import { hasAkordaApplicationAccess } from "@app/entities/users";
import { Api } from "@app/API/_base";
import isDeepEqual from "fast-deep-equal/react";
import debounce from "lodash/debounce";
import { useTranslation as useI18nTranslation } from "react-i18next";
import { getTranslationOverrides } from "@app/redux/data/config";
import { AuthContext } from "@app/_Login/components/Auth/AuthProvider";

/**
 * A hook that wraps the react-i18n `useTranslation` hook to allow for
 * company level overrides of translations. The options arg is not
 * currently supported for an overriden translation, but is still
 * passed along to the `t()` func when an override is not found.
 */
export const useTranslation = () => {
  const { t } = useI18nTranslation();
  const companyId = useSelector(getMyCompanyId);
  const translationOverrides = useSelector(getTranslationOverrides);

  const companyOverrides = useMemo(() => {
    return get(translationOverrides, `${companyId}`) || {};
  }, [companyId, translationOverrides]);

  const translate = useCallback(
    (
      key: string,
      options?: Record<string, string | number>,
      fallback?: string // fallback (optional) that will be used if the translation is same as key (failed lookup)
    ) => {
      const override = get(companyOverrides, key);
      const translation = override || t(key, options);
      return translation === key && fallback ? fallback : translation;
    },
    [companyOverrides, t]
  );

  return { t: translate };
};

export const useMyProfile = () => {
  const authContext = useContext(AuthContext);
  return [
    authContext.userProfile,
    { hasLoaded: true, hasError: false, isLoading: false },
    hasAkordaApplicationAccess(authContext.userProfile),
  ];
};

/**
 * If an alias is passed in, it will be used.
 * Returns the login url for federated users or the default url (/login) if not a federated user
 * @deprecated
 * @returns url
 */
export const useLoginUrl = (alias?: string) => {
  const dispatch = useDispatch();
  const loginUrl = useSelector(getLoginUrl);

  useEffect(() => {
    if (!loginUrl) {
      dispatch(fetchLoginUrl(alias));
    }
  }, [loginUrl, dispatch, alias]);

  return loginUrl;
};

/**
 * Returns a function which, when invoked, will return a boolean value indicating whether or not
 * the component is mounted.
 * @returns ref
 */
export const useIsMounted = (): (() => boolean) => {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);
  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
};

/**
 * Custom hook to run a callback when component unmounts
 * @param callback Function to run on component unmount
 */
export const useUnmount = (callback: () => void) => {
  // Use a ref to store the callback to avoid dependency changes
  const callbackRef = useRef(callback);

  // Update the ref whenever the callback changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Only run the cleanup function when the component unmounts
  useEffect(() => {
    return () => {
      callbackRef.current();
    };
  }, []); // Empty dependency array ensures this only runs on mount/unmount
};

/**
 * This hook provides the query string in object form, and a function that will update the query string
 */
export const useQueryString = () => {
  const { search } = useLocation();
  const history = useHistory();

  const update = useCallback(
    (update: Object = {}) => {
      const qs = {
        ...queryString.parse(window.location.search),
        ...update,
      };
      history.replace(`?${queryString.stringify(qs)}`);
    },
    [history]
  );

  return useMemo(() => {
    const query = queryString.parse(search);
    const remove = (params: string | string[]) => {
      params = !Array.isArray(params) ? [params] : params;

      const query = queryString.parse(search);
      params.forEach((param) => {
        delete query[param];
      });
      history.replace(`?${queryString.stringify(query)}`);
    };
    return [query as any, update, remove];
  }, [search, history, update]);
};

/**
 * This hook looks for changes to the query string in the URL and executes the provided callback
 * whenever the query string values change. The consumer can provide an optional array of parameter
 * names to watch, rather than watching the entire search query string.
 * @param callback function
 * @param params an array of param names that should be included
 */
export const useQueryChangeEffect = (callback: Function, params?: string[]) => {
  const location = useLocation();
  let search = useMemo(() => {
    let query: string = location.search;
    if (params) {
      const q = queryString.parse(query);
      query = queryString.stringify(pick(q, params));
    }
    return query;
  }, [params, location.search]);

  // execute the provided callback whenever the search query string changes
  useEffect(() => {
    callback(queryString.parse(search));
  }, [search, callback]);
};

/**
 * Returns a helper object that contains handlers for common pagination operations
 * todo: remove paginationInfo argument (previously used for index out of bounds, but can conflict with other hooks that use it)
 */
export const usePagination = (_paginationInfo?: IPagination) => {
  const [query, updateQueryString] = useQueryString();

  const page = query.page ? +query.page - 1 : 0;

  return useMemo(
    () => ({
      page,
      changePage: (page: number = 0) => {
        updateQueryString({ page: page + 1 });
      },
      sort: (sortInfo: ISortInfo) => {
        updateQueryString({
          orderBy: `${sortInfo.sortBy} ${
            sortInfo.isDescending ? "DESC" : "ASC"
          }`,
          page: undefined,
        });
      },
    }),
    [page, updateQueryString]
  );
};

/**
 * Returns a previous value of a prop or state
 * @param value
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Given one or more redux selectors that return boolean values, will return
 * true if all of them resolve to true, otherwise false. This can be convenient
 * for `isLoading` when we need to check multiple selectors
 */
export const useSelectorAny = (
  ...args: OutputSelector<any, boolean, (res: any) => boolean>[]
) => {
  const values = [];
  args.forEach((sel) => {
    // eslint-disable-next-line
    values.push(useSelector(sel));
  });
  return values.some((v) => v === true);
};

/**
 * This hook provides the load/save application data from/into localStorage state
 */
export const useApplicationLocalStorage = (
  key: string,
  initialState: any = null
) => {
  const storedData = loadStateFromLocalStorage(key);
  const [data, saveData] = useState(
    !isNil(storedData) ? storedData : initialState
  );

  const save = useCallback(
    (newData) => {
      if (data !== newData) {
        saveStateInLocalStorage(key, newData);
        saveData(loadStateFromLocalStorage(key));
      }
    },
    [data, key]
  );

  const clean = useCallback(() => cleanStateFromLocalStorage(key), [key]);

  return [data, save, clean];
};

export const useRedirectUrl = () => {
  const [data, saveData, cleanData] =
    useApplicationLocalStorage("akordaRedirectUrl");
  const history = useHistory();

  const isBlackListedRedirectUrl = useCallback((redirectUrl: string) => {
    return constants.REDIRECT_URL_BLACKLIST.includes(redirectUrl);
  }, []);

  const redirect = useCallback(
    (url?: string) => {
      history.push(url || data || "/");
      cleanData();
    },
    [history, data, cleanData]
  );

  const save = useCallback(
    (pathname: string, search: string = "", hash: string = "") => {
      if (!isBlackListedRedirectUrl(pathname)) {
        const url = `${pathname}${search}${hash}`;
        saveData(url);
      }
    },
    [isBlackListedRedirectUrl, saveData]
  );

  const redirectUrl = useMemo(
    () => (!isBlackListedRedirectUrl(data) ? data : ""),
    [data, isBlackListedRedirectUrl]
  );

  return [redirectUrl, save, redirect, cleanData];
};

/**
 * How to deal react callback ref cleanup function
 * There is an open issue and a proposed solution that works
 * https://github.com/facebook/react/issues/15176#issuecomment-512740882
 * @param rawCallback
 * @returns
 */
export const useCallbackRef = (rawCallback) => {
  const cleanupRef = useRef(null);
  const callback = useCallback(
    (node) => {
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = null;
      }

      if (node) {
        cleanupRef.current = rawCallback(node);
      }
    },
    [rawCallback]
  );

  return callback;
};

/**
 * This hook provide an api to log the visited page along with optional data
 * @param pageName
 * @param data
 */
export const usePageView = (name: string, data: any = {}) => {
  const [currentData, setData] = useState(data); // to memoized the data object

  if (!isDeepEqual(currentData, data)) {
    setData(data);
  }

  useEffect(() => {
    if (isDevelopment) return;
    Api.execute({
      url: `/page-view`,
      method: "POST",
      data: {
        name,
        data: currentData,
      },
    });
  }, [name, currentData]);
};

export const useWindowSize = () => {
  const [size, setSize] = useState(null);

  const calculateSize = useCallback(() => {
    const referenceElement = document.body;
    const { height, width } = referenceElement.getBoundingClientRect();
    return setSize({ height, width });
  }, []);

  useEffect(() => {
    const calculateSizeDebounced = debounce(calculateSize, 200);
    calculateSize();
    window.addEventListener("resize", calculateSizeDebounced);
    return () => {
      window.removeEventListener("resize", calculateSizeDebounced);
    };
  }, [calculateSize]);

  return size || { width: 0, height: 0 };
};

export const useOnClickInside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        if (ref.current && ref.current.contains(event.target)) {
          handler(event);
        }
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

export const useBroadcastChannel = (name) => {
  const [message, setMessage] = useState();
  const channel = useRef(null);

  useEffect(() => {
    channel.current = new BroadcastChannel(name);

    channel.current.addEventListener("message", (event) => {
      setMessage(event.data);
    });

    return () => {
      channel.current?.close();
    };
  }, [name]);

  const sendMessage = useCallback((data) => {
    channel.current?.postMessage(data);
  }, []);

  return {
    message,
    sendMessage,
  };
};
