// react
import { createContext, useMemo } from "react";
import { Redirect } from "react-router-dom";

// external libraries
import { useDispatch } from "react-redux";

// _Login
import { setUserContext } from "@app/_Login/actions";

// API
import * as API from "@app/API";

// redux
import { hasDataError, hasDataLoaded, isDataLoading } from "@app/redux/utils";
import { setPreferences, usePreferences } from "@app/redux/data/preferences";

// toolkit
import Spinner from "@app/toolkit/Spinner";

// utils
import { useAsync } from "@app/utils/react-async-hook";
import { useMyProfile } from "@app/utils/hooks";

// local
import { getLoginRedirectUrl } from "./utils";
import AccessDenied from "../AccessDenied";

export interface AuthContextValue {
  isAuthenticated: boolean;
  userProfile: any;
  preferences: any;
  integrations: any[];
  refresh: () => Promise<any>;
}

const defaultContextValue: AuthContextValue = Object.freeze({
  isAuthenticated: false,
  userProfile: null,
  preferences: null,
  integrations: [],
  refresh: () => Promise.resolve(null),
});

export const useIdentity = () => {
  const dispatch = useDispatch();
  const [myProfile] = useMyProfile();
  const [preferences] = usePreferences();

  const { status, result, execute } = useAsync<AuthContextValue>(
    API.getIdentity,
    []
  );

  if (result?.userProfile && !myProfile) {
    dispatch(setUserContext(result.userProfile));
  }

  if (result?.preferences && !preferences) {
    dispatch(setPreferences(result.preferences));
  }

  return useMemo(
    () => ({ identity: result, identityStatus: status, refresh: execute }),
    [result, status, execute]
  );
};

export const AuthContext = createContext<AuthContextValue>(defaultContextValue);

function AuthProvider({ children }) {
  const {
    identity = defaultContextValue,
    identityStatus,
    refresh,
  } = useIdentity();

  const { status: companyStatus, result: companyResponse } = useAsync(
    API.fetchMyCompany,
    []
  );

  const myProfile = useMemo(() => {
    return {
      ...identity,
      refresh,
    };
  }, [refresh, identity]);

  const isAuthorized = useMemo(() => {
    if (!hasDataLoaded(companyStatus) || hasDataError(companyStatus))
      return false;

    // @ts-expect-error
    return companyResponse?.data?.products?.LSC;
  }, [companyResponse, companyStatus]);

  if (isDataLoading(identityStatus) || isDataLoading(companyStatus))
    return <Spinner modal />;

  if (hasDataError(identityStatus)) return <Redirect to={"/unauthorized"} />;

  if (!identity.isAuthenticated) {
    window.location.href = getLoginRedirectUrl();
    return null;
  }

  return (
    <AuthContext.Provider value={myProfile}>
      {isAuthorized && children}

      {!isAuthorized && <AccessDenied />}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
