import { SettingsEntity, SettingsEntityAcl } from "@app/entities/settings";
import axios, { AxiosResponse } from "axios";
import { get } from "@app/utils/lodash";
import { Api, ApiResponse } from "./_base";

/**
 * Returns the list of entities for the current user
 */
export function loadEntities(
  schemaName: string
): Promise<ApiResponse<SettingsEntity<any>[]>> {
  return Api.execute({
    url: `/api/proxy/api-settings/v1/schemas/${schemaName}/entities`,
  });
}

export function createEntity(schemaName: string, data: any, isPublic = false) {
  return axios.post(
    `/api/proxy/api-settings/v1/schemas/${schemaName}/entities`,
    { data, isPublic },
    {
      withCredentials: true,
    }
  );
}

export function deleteEntity(entityId: string): Promise<AxiosResponse<any>> {
  return axios.delete(`/api/proxy/api-settings/v1/entities/${entityId}`, {
    withCredentials: true,
  });
}

export function loadEntity(
  entityId: string
): Promise<AxiosResponse<SettingsEntity<any>>> {
  return axios.get(`/api/proxy/api-settings/v1/entities/${entityId}`, {
    withCredentials: true,
  });
}

export function loadEntityAcl(
  entityId: string
): Promise<AxiosResponse<{ acl: SettingsEntityAcl[] }>> {
  return axios.get(`/api/proxy/api-settings/v1/entities/${entityId}/share`, {
    withCredentials: true,
  });
}

export function loadEntityDetails(entityId: string): Promise<any> {
  return new Promise((resolve, reject) => {
    Promise.allSettled([loadEntity(entityId), loadEntityAcl(entityId)]).then(
      ([entity, acl]) => {
        // if the acl call fails with a 403, the user doesn't have permission to edit
        // and we'll treat that as an empty acl
        if (
          entity.status === "rejected" ||
          (acl.status === "rejected" &&
            get(acl, "reason.response.status") !== 403)
        ) {
          const rejectReason = get(entity, "reason", get(acl, "reason"));
          reject(rejectReason);
        } else {
          resolve({
            entity: entity.value.data,
            acl: acl.status === "rejected" ? [] : acl.value.data.acl,
          });
        }
      }
    );
  });
}

export function updateEntity(
  entityId: string,
  data: Partial<SettingsEntity<any>>
): Promise<AxiosResponse<SettingsEntity<any>>> {
  return axios.patch(`/api/proxy/api-settings/v1/entities/${entityId}`, data, {
    withCredentials: true,
  });
}

export function shareEntity(entityId: string, acl: SettingsEntityAcl) {
  return axios.post(
    `/api/proxy/api-settings/v1/entities/${entityId}/share`,
    { acl },
    {
      withCredentials: true,
    }
  );
}

export function unshareEntity(entityId, userIds: string[]) {
  return axios.put(
    `/api/proxy/api-settings/v1/entities/${entityId}/share`,
    { principals: userIds },
    {
      withCredentials: true,
    }
  );
}

export function loadSchema(schemaName: string) {
  return axios.get(`/api/proxy/api-settings/v1/schemas/${schemaName}`, {
    withCredentials: true,
  });
}

export function getPreferences() {
  return Api.execute({
    url: `/api/settings/preferences`,
  });
}

export function updatePreferences(update: Record<string, any>) {
  return Api.execute({
    url: `/api/settings/preferences`,
    method: "PATCH",
    data: update,
  });
}

export function getCompanySettings() {
  return Api.execute({
    url: `/api/settings/company-settings`,
  });
}

export function updateCompanySettings(update: Record<string, any>) {
  return Api.execute({
    url: `/api/settings/company-settings`,
    method: "PATCH",
    data: update,
  });
}
