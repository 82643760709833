import { Ticket } from "@app/entities/tickets/tickets";
import { FileInfo } from "@app/types-business/documents";
import queryString, { ParsedQuery } from "query-string";
import { ApiResponse, Api } from "./_base";
import moment from "moment-timezone";
import { SourceType } from "@app/entities/document";

export const loadTickets = (query: ParsedQuery) => {
  return Api.execute({
    url: `/api/tickets?${queryString.stringify(query)}`,
  });
};

export const loadTicket = (ticketId: string): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}`,
  });
};

export const createTicket = (
  ticketRequest: any
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: "/api/tickets",
    method: "POST",
    data: ticketRequest,
  });
};

// For many ticket types, the creation of a ticket happens in two steps: first, the
// ticket is created with minimal information, and then second, an update occurs immediately
// to update title/assignee/summary/etc. We have a special endpoint in the BFF API for this
// to allow users with ui-ticket:create permission to create tickets without having ui-ticket:write
export const createTicketUpdate = (
  ticketId: string,
  data: Partial<Ticket>
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/post-create-update/${ticketId}`,
    method: "PATCH",
    data,
  });
};

export const updateTicket = (
  ticketId: string,
  data: Partial<Ticket>
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}`,
    method: "PATCH",
    data,
  });
};

export const assignTicket = (
  ticketId: string,
  assignee: string | null,
  message?: string
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/assign`,
    method: "POST",
    data: {
      ticketId,
      assignee,
      message,
    },
  });
};

export interface AddTicketAttachmentRequest {
  ticketId: string;
  file: FileInfo;
}

export const addAttachment = (
  request: AddTicketAttachmentRequest
): Promise<ApiResponse<Ticket>> => {
  const { ticketId, file } = request;
  const addAttachments = [file];
  return Api.execute({
    url: `/api/tickets/${ticketId}/add-attachment`,
    method: "POST",
    data: {
      addAttachments,
    },
  });
};

export const addRelatedDocumentToTicket = (
  ticketId: string,
  relatedDocumentId: string
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/add-related-document`,
    method: "POST",
    data: {
      relatedDocumentId,
    },
  });
};

export const addRelatedTicket = (
  ticketId: string,
  relatedTicketId: string
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/add-related`,
    method: "POST",
    data: {
      relatedTicketId,
    },
  });
};

export const removeAttachment = (
  ticketId: string,
  removeAttachmentIds: string[]
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/remove-attachment`,
    method: "POST",
    data: {
      removeAttachmentIds,
    },
  });
};

export const removeRelatedDocumentFromTicket = (
  ticketId: string,
  relatedDocumentId: string
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/remove-related-document`,
    method: "POST",
    data: {
      relatedDocumentId,
    },
  });
};

export const removeRelatedTicket = (
  ticketId: string,
  relatedTicketId: string
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/remove-related`,
    method: "POST",
    data: {
      relatedTicketId,
    },
  });
};

export function removeTicket(ticketId: string) {
  return Api.execute({
    url: `/api/tickets/${ticketId}`,
    method: "DELETE",
  });
}

/**
 * Loads the history information for a given ticket
 * @param ticketId
 */
export function getTicketHistory(
  ticketId: string,
  query: Record<string, any> = {}
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/tickets/${ticketId}/history?${queryString.stringify(query)}`,
  });
}

export function getTicketMetrics(ticketId: string): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/tickets/${ticketId}/metrics`,
  });
}

export const followTicket = (
  ticketId: string,
  userId: string
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/follow/${userId}`,
    method: "POST",
  });
};

export const unfollowTicket = (
  ticketId: string,
  userId: string
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/unfollow/${userId}`,
    method: "DELETE",
  });
};

export function exportTicketsAsCSV(query: Record<string, any> = {}) {
  query["filters.tz"] = moment().utcOffset();
  return Api.execute({
    url: `/api/proxy/api-tickets/v1/tickets/export?${queryString.stringify(
      query
    )}`,
  });
}

export const promoteAttachment = (
  ticketId: string,
  fileId: string,
  counterparty?: {
    id?: string;
    name?: string;
  },
  metadata?: any,
  playbookId?: number,
  sourceType?: SourceType
): Promise<ApiResponse<Ticket>> => {
  return Api.execute({
    url: `/api/tickets/${ticketId}/promote-attachment`,
    method: "POST",
    data: {
      fileId,
      counterparty,
      metadata,
      sourceType,
      ...(playbookId && {
        playbookId: playbookId.toString(),
      }),
    },
  });
};
