import constants from "@app/utils/constants";
import { get, isEmpty, keyBy } from "@app/utils/lodash";
import { PlaybookClause } from "./playbook-clause";
import moment from "moment";

const { PLAYBOOKS_STATUS_MAP, PLAYBOOK_PAPER_TYPES } = constants;
const { PROCESSING, UNCONFIRMED, FAILED, PUBLISHED, ARCHIVED, DRAFT } =
  PLAYBOOKS_STATUS_MAP;
const { COMPANY_PAPER, COUNTERPARTY_PAPER } = PLAYBOOK_PAPER_TYPES;

export interface Playbook {
  id: number;
  name: string;
  defaultSigner?: number;
  description: string;
  type: string;
  paperType: string;
  referenceId: string;
  updatedDate?: string;
  publishedDate?: string;
  count?: number;
  status?: string;
  abbreviation?: string;
  stats?: any;
  clauses?: PlaybookClause[];
  frictionConfig?: any;
}

export interface ContractType {
  type: string;
  name: string;
}

export const isPlaybookSetupIncomplete = (playbook: Playbook) =>
  [PROCESSING, UNCONFIRMED, FAILED].includes(get(playbook, "status"));

export const isCompanyPaperPlaybook = (playbook: Playbook) =>
  get(playbook, "paperType") === COMPANY_PAPER;

export const isCounterpartyPaperPlaybook = (playbook: Playbook) =>
  get(playbook, "paperType") === COUNTERPARTY_PAPER;

export const isArchivedPlaybook = (playbook: Playbook) =>
  getPlaybookStatus(playbook) === ARCHIVED;

export const isDraftPlaybook = (playbook: Playbook) =>
  getPlaybookStatus(playbook) === DRAFT;

export const isPublishedPlaybook = (playbook: Playbook) =>
  getPlaybookStatus(playbook) === PUBLISHED;

export const isUnconfirmedPlaybook = (playbook: Playbook) =>
  getPlaybookStatus(playbook) === UNCONFIRMED;

export const isPlaybookAvailableForAssembly = (playbook: Playbook) =>
  get(playbook, "forAssembly");

export const getPlaybookAbbreviation = (playbook: Playbook) =>
  get(playbook, "abbreviation");

export const getPlaybookName = (playbook: Playbook) =>
  get(playbook, "name") || "";

export const getPlaybookStatus = (playbook: Playbook) =>
  get(playbook, "status");

export const getPlaybookId = (playbook: Playbook) => get(playbook, "id");

export const getPlaybookClauses = (playbook: Playbook) =>
  get(playbook, "clauses") || [];

export const getFrictionConfig = (playbook: Playbook) =>
  get(playbook, "frictionConfig") || {};

export const getTemplateFileId = (playbook: Playbook) =>
  get(playbook, "templateDocxFileId", null);

export const getTemplateFileName = (playbook: Playbook) =>
  get(playbook, "templateDocxFileName", `${getPlaybookName(playbook)}.docx`);

export const SIGNATURE_FIELDS = [
  {
    id: "signature.user_1",
    name: "signature.user_1",
    displayName: "Company Signer's Signature",
    description: "The signature line for our company's signer",
    signature: true,
    replacement: true,
    playbookId: -1,
  },
  {
    id: "signature.counterparty_1",
    name: "signature.counterparty_1",
    displayName: "Counterparty Signer's Signature",
    description: "The signature line for the counterparty's signer",
    signature: true,
    replacement: true,
    playbookId: -1,
  },
  {
    id: "signature.user_1_name",
    name: "signature.user_1_name",
    displayName: "Company Signer's Full Name",
    description:
      "The full name (first name and surname) of our company's signer",
    signature: true,
    replacement: true,
    playbookId: -1,
  },
  {
    id: "signature.counterparty_1_name",
    name: "signature.counterparty_1_name",
    displayName: "Counterparty Signer's Full Name",
    description:
      "The full name (first name and surname) of the counterparty's signer",
    signature: true,
    replacement: true,
    playbookId: -1,
  },
  {
    id: "signature.user_1_title",
    name: "signature.user_1_title",
    displayName: "Company Signer's Title",
    description: "This is the role (job title) for our company's signer",
    signature: true,
    replacement: true,
    playbookId: -1,
  },
  {
    id: "signature.counterparty_1_title",
    name: "signature.counterparty_1_title",
    displayName: "Counterparty Signer's Title",
    description: "This is the role (job title) for the counterparty's signer",
    signature: true,
    replacement: true,
    playbookId: -1,
  },
  {
    id: "signature.user_1_date",
    name: "signature.user_1_date",
    displayName: "Company Signature Date",
    description: "A placeholder for the signing date for our company",
    signature: true,
    replacement: true,
    playbookId: -1,
  },
  {
    id: "signature.counterparty_1_date",
    name: "signature.counterparty_1_date",
    displayName: "Counterparty Signature Date",
    description: "A placeholder for the signing date for the counterparty",
    signature: true,
    replacement: true,
    playbookId: -1,
  },
];

export const getSignatureFieldsAsMap = () => keyBy(SIGNATURE_FIELDS, "name");

export const getPlaybookNameWithAbbreviation = (playbook: Playbook) => {
  const abbreviation = getPlaybookAbbreviation(playbook);
  const name = getPlaybookName(playbook);
  return !isEmpty(abbreviation) ? `${name} (${abbreviation})` : name;
};

const getDefaultStructureSettings = (schema?: any) => {
  return get(schema, "default") || { playbookIncludeTitle: true };
};

export const getPlaybookStructureSettings = (
  playbook: Playbook,
  schema?: any
) =>
  get(playbook, `structureSettings.${STRUCTURE_SETTING_NAME}`) ||
  getDefaultStructureSettings(schema);

export const hidePlaybookTitle = (playbook: Playbook) =>
  get(getPlaybookStructureSettings(playbook), "playbookIncludeTitle") === false;

export const isPublishable = (playbook: Playbook) => {
  const updatedDate = moment(get(playbook, "updatedDate"));
  const oneHourAgo = moment().subtract(1, "hour");
  return (
    (!isEmpty(getPlaybookClauses(playbook)) && isDraftPlaybook(playbook)) ||
    ((!isPublishedPlaybook(playbook) || !isArchivedPlaybook(playbook)) &&
      updatedDate.isBefore(oneHourAgo))
  );
};

export const STRUCTURE_SETTING_NAME = "PLAYBOOK";
