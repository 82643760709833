import React from "react";
import { Button as ReactstrapButton, ButtonProps } from "reactstrap";
import classNames from "classnames";
import SvgSpinner from "../../svgSpinner";
import s from "./styles.module.scss";

export interface AkordaButtonProps extends ButtonProps {
  dataElmId?: string;
  isSubmitting?: boolean;
}

const Button: React.FC<AkordaButtonProps> = ({
  className,
  children,
  dataElmId,
  disabled = false,
  isSubmitting = false,
  ...rest
}) => {
  return (
    <ReactstrapButton
      className={classNames(
        "akorda-form-component",
        "akorda-button",
        s.button,
        {
          [s.isProcessing]: isSubmitting,
        },
        className
      )}
      data-elm-id={dataElmId}
      disabled={disabled || isSubmitting}
      {...rest}
    >
      {children}
      {isSubmitting && <SvgSpinner xsmall className={s.buttonSpinner} />}
    </ReactstrapButton>
  );
};

export default Button;
