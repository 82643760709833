// The URL for the Akorda BFF server is baked into the webpack bundle
// @ts-ignore
const BASE_URL = "";

const constants = {
  BASE_URL,
  DEFAULT_DATE_FORMAT: "MMM D, YYYY",
  DEAD_SESSION_MSG: "INVALID_TOKEN",
  MISSING_SESSION_MSG: "TOKEN_MISSING",
  ROLES: {
    ADMIN: "ADMIN",
    COMPANY_ADMIN: "COMPANY_ADMIN",
    SALES: "SALES",
    SALES_MANAGEMENT: "SALES_MANAGEMENT",
    LEGAL: "LEGAL",
    LEGAL_MANAGEMENT: "LEGAL_MANAGEMENT",
    LEGAL_INSIGHTS: "LEGAL_INSIGHTS",
    TRIAL_COMPANY_ADMIN: "TRIAL_COMPANY_ADMIN",
    REVENUE: "REVENUE",
    SIGNER: "SIGNER",
    BUSINESS: "BUSINESS",
  },
  VERIFY_USER_PAGE_TYPE: "activate",
  CHANGE_PASSWORD_PAGE_TYPE: "change",
  EXPIRED_PASSWORD_PAGE_TYPE: "expired",
  USER_STATUS_MAP: {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    SUSPENDED: "SUSPENDED",
  },
  STATUS_MAP: {
    ACTIVE: "user.active-status-label",
    PENDING: "user.pending-status-label",
    SUSPENDED: "user.suspended-status-label",
  },
  PROCCESS_STATUS_MAP: {
    PROCESSING: "PROCESSING",
    OPEN: "OPEN",
    COMPLETE: "COMPLETE",
    FINAL: "FINAL",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    AWAITING_APPROVAL_IN_QUEUE: "AWAITING_APPROVAL_IN_QUEUE",
    DELETED: "DELETED",
    FAILED: "FAILED",
    PROCESSED: "PROCESSED",
    REPROCESSING: "REPROCESSING",
    INITIAL: "INITIAL",
    EXECUTED: "EXECUTED",
    ARCHIVED: "ARCHIVED",
    FILED: "FILED",
    PENDING: "PENDING",
  },
  ACTION_MAP: {
    ACTIVE: "globals.suspend-action-label",
    PENDING: "globals.resend-action-label",
    SUSPENDED: "globals.unsuspend-action-label",
  },
  PAGES: {
    MY_DOCUMENTS: "My Documents",
    ALL_DOCUMENTS: "All Documents",
  },
  PROJECT_METADATA: {
    PARTY_A: "partyA",
    PARTY_B: "partyB",
    TYPE: "contractType",
    RISKS: "risks",
    OWNER_ID: "ownerUserId",
    OWNER: "owner",
    RISK: "risk",
    EFFECTIVE_DATE: "effectiveDate",
    TERM: "term",
    GOVERNING_LAW: "governingLaw",
  },
  RISK_STATUS_MAP: {
    OPEN: "OPEN",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    AWAITING_APPROVAL_IN_QUEUE: "AWAITING_APPROVAL_IN_QUEUE",
    APPROVED: "APPROVED",
    APPROVED_WITH_EDIT: "APPROVED_WITH_EDIT",
    DENIED: "DENIED",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    REVISED: "REVISED",
    PARTIAL_ACCEPTED: "PARTIAL_ACCEPTED",
    PARTIAL_REJECTED: "PARTIAL_REJECTED",
    PARTIAL_REVISED: "PARTIAL_REVISED",
    REMOVED: "REMOVED",
    MATCHED: "MATCHED",
    MISSING: "MISSING",
    ADDED: "ADDED",
    DONT_ADDED: "DONT_ADDED",
    UNCHANGED: "UNCHANGED",
    DONE: "DONE",
  },
  RISK_STATUS_INDEX: {
    OPEN: 0,
    PARTIAL_ACCEPTED: 0,
    PARTIAL_REJECTED: 0,
    PARTIAL_REVISED: 0,
    AWAITING_APPROVAL: 1,
    AWAITING_APPROVAL_IN_QUEUE: 1,
    APPROVED: 2,
    APPROVED_WITH_EDIT: 2,
    REJECTED: 3,
    DENIED: 4,
    ACCEPTED: 5,
    REVISED: 6,
    REMOVED: 7,
  },
  RISK_PROCCESSED_GROUP: [
    "APPROVED",
    "APPROVED_WITH_EDIT",
    "ACCEPTED",
    "REJECTED",
    "REVISED",
    "REMOVED",
  ],
  RISK_OPEN_GROUP: [
    "OPEN",
    "PARTIAL_ACCEPTED",
    "PARTIAL_REJECTED",
    "PARTIAL_REVISED",
  ],
  RISK_OPEN_GROUP_EXTENDED: [
    "OPEN",
    "PARTIAL_ACCEPTED",
    "PARTIAL_REJECTED",
    "PARTIAL_REVISED",
    "DENIED",
    "AWAITING_APPROVAL",
    "AWAITING_APPROVAL_IN_QUEUE",
  ],
  RISK_APPROVAL_GROUP: [
    "APPROVED",
    "APPROVED_WITH_EDIT",
    "DENIED",
    "AWAITING_APPROVAL",
    "AWAITING_APPROVAL_IN_QUEUE",
  ],
  RISK_LEVEL: {
    LOW: {
      label: "LOW",
      value: 0,
    },
    MEDIUM: {
      label: "MEDIUM",
      value: 0.2,
    },
    HIGH: {
      label: "HIGH",
      value: 0.3,
    },
  },
  RISK_TYPE: {
    FINANCIAL: "financial",
    LEGAL: "legal",
    BUSINESS: "business",
    REGULATORY: "regulatory",
  },
  LABEL_RISK_CLASS: {
    HIGH: "danger",
    MEDIUM: "warning",
    LOW: "success",
  },
  RISK_MAP: {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
    NO_RISK: "NO_RISK",
  },
  SPACE_UNIT: 5,
  CONFIG_COLORS: {
    PROGRESS_BAR_SUCCESS: "#51AF46",
  },
  ERRORS: {
    NO_CONTENT: "No Content",
    APPROVAL_REQUEST_OUTSTANDING: "APPROVAL_REQUEST_OUTSTANDING",
    INVALID_ACTION_TOKEN: "INVALID_ACTION_TOKEN",
    QUOTA_LIMIT_REACHED: "QUOTA_LIMIT_REACHED",
    CLAUSE__LOCKED_BY_ACTION: "CLAUSE__LOCKED_BY_ACTION",
  },
  USER_EVENTS: {
    NO_RED_LINE_FOUND: "no-red-line-found",
    CORRUPT_PROJECT_FILE: "corrupt-project-file",
    ANALYSIS_COMPLETED: "contract-risk-analysis-completed",
    DOCUMENT_PROCESSING_FAILED: "document-processing-failed",
    APPROVE_REQUEST_EXECUTED: "request-approval-executed",
    APPROVE_REQUEST_PENDING: "request-approval-pending",
    PLAYBOOK_PROCESSED: "playbook-processed",
    PLAYBOOK_FAILED: "playbook-failed",
    PLAYBOOK_PUBLISHED: "playbook-published",
    DOCUMENT_PROCESSED: "document-processed",
    DOCUMENT_UPDATED: "UPDATED",
  },
  CLAUSES_SORT_OPTIONS: {
    BY_PAGE: "page-order",
    BY_RISK: "risk",
    BY_STATUS: "status",
  },
  PARAGRAPH_IDENTIFIER: "data-uid",
  CHANGE_IDENTIFIER: "data-id",
  HTML_TRANSFORMATION_UPDATE_FORM: {
    type: "html-editor",
    fileName: "html-editor.html",
  },
  TRANSFORMATION_TYPE: {
    EDITOR: "html-editor",
    CACHE: "html-cache",
  },
  BALLON_TOOLBAR_TYPE: {
    NONE: "NONE",
    DEFAULT: "DEFAULT",
    MODIFY: "MODIFY",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    APPROVED: "APPROVED",
    DENIED: "DENIED",
  },
  CLAUSE_CARD_ID_PREFIX: "clause_card_",
  ROLE_MAP: {
    ADMIN: "role.admin",
    COMPANY_ADMIN: "role.company-admin",
    SALES: "role.sales",
    SALES_MANAGEMENT: "role.sales-management",
    LEGAL: "role.legal",
    LEGAL_MANAGEMENT: "role.legal-management",
    LEGAL_INSIGHTS: "role.legal-insights",
    REVENUE: "role.revenue",
    SIGNER: "role.signer",
    BUSINESS: "role.business",
  },
  AKORDA_WEBSITE: "https://legalsifter.com/",
  ACTION_TOKEN_TYPES: {
    CLAUSE: "CLAUSE",
  },
  VOID_DATA: {
    USER_CONTEXT: {
      company: {
        companyId: 0,
        name: "companyName",
      },
      groups: [],
      userId: 0,
      email: "email",
      profileId: "profileId",
      firstName: "firstName",
      lastName: "lastName",
      latestLoginDate: "latestLoginDate",
      latestPasswordChangeDate: "latestPasswordChangeDate",
      verificationDate: "verificationDate",
      createdDate: "createdDate",
      status: "status",
    },
  },
  TOAST_DEFAULT_TIME: 5000,
  CLAUSE_ACTION_MAP: {
    ACCEPT: "ACCEPT",
    REJECT: "REJECT",
    GET_APPROVAL: "GET_APPROVAL",
    CANCEL_APPROVAL: "CANCEL_APPROVAL",
    APPROVE: "APPROVE",
    DENY: "DENY",
    REOPEN: "REOPEN",
    REVISE: "REVISE",
    PARTIAL_ACCEPT: "PARTIAL_ACCEPT",
    PARTIAL_REJECT: "PARTIAL_REJECT",
    PARTIAL_REVISE: "PARTIAL_REVISE",
    REVERT: "REVERT",
    ENQUEUE_APPROVAL: "ENQUEUE_APPROVAL",
    MULTIPLE_ACCEPT: "MULTIPLE_ACCEPT",
    MULTIPLE_REJECT: "MULTIPLE_REJECT",
    EDIT: "EDIT",
    DONE: "DONE",
    MAKE_FINAL: "MAKE_FINAL",
    REJECT_ALL: "REJECT_ALL",
    MAKE_IN_NEGOTIATION: "MAKE_IN_NEGOTIATION",
  },
  AUTO_SAVE_TIME: 5,
  CLAUSE_PARTIAL_ACTION_GROUP: [
    "PARTIAL_ACCEPT",
    "PARTIAL_REJECT",
    "PARTIAL_REVISE",
    "REVERT",
  ],
  CLAUSE_PARTIAL_CLAUSE_GROUP: [
    "PARTIAL_ACCEPTED",
    "PARTIAL_REJECTED",
    "PARTIAL_REVISED",
  ],
  CLAUSE_SUMARY_ROUTE: "/project/summary",
  CLAUSE_INSTANCE_TYPE: {
    FALLBACK: "FALLBACK",
    PAST_EXAMPLE: "PAST_EXAMPLE",
    STANDARD: "STANDARD",
    GUIDANCE: "GUIDANCE",
    COMMENT: "COMMENT",
  },
  DEFAULT_SORTERS: {
    PROJECT_LIST: "updated_date",
    ACCOUNT_LIST: "name",
    CLAUSE_EXAMPLES_LIST: "dateClosed",
    CONTRACT_LIST: "updatedDate",
  },
  CONFIRMATION_TYPES: {
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    WARNING: "WARNING",
  },
  ALERT_TYPES: {
    APPROVAL_REQUEST_UNCHANGED: "APPROVAL_REQUEST_UNCHANGED",
    APPROVAL_RESPONSE_DENIED: "APPROVAL_RESPONSE_DENIED",
    APPROVAL_RESPONSE_APPROVED: "APPROVAL_RESPONSE_APPROVED",
    PROJECT_UNCHANGED: "PROJECT_UNCHANGED",
  },

  EMAIL_REGEX:
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-next-line no-useless-escape
  PHONE_NUMBER_REGEX: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  FORMATTING_COMMANDS: {
    BOLD: "bold",
    ITALIC: "italic",
    STRIKE: "strike",
    UNDERLINE: "underline",
  },
  FORMATTING_COMMANDS_GROUP: ["bold", "italic", "strike", "underline"],
  TAGS_BY_COMMAND: {
    bold: "b",
    italic: "i",
    strike: "s",
    underline: "u",
    bulletedlist: "ul",
    numberedlist: "ol",
  },
  FORMATTING_TAGS_GROUP: ["b", "i", "s", "u", "span"],
  FORMATTING_WITH_LINES_TAGS_GROUP: ["s", "u"],
  PARAGRAPH_TAGS_GROUP: ["p", "h1", "h2", "h3", "pre", "li"],
  PARAGRAPH_COMMANDS_GROUP: [
    "justifycenter",
    "justifyleft",
    "justifyright",
    "justifyblock",
  ],
  PARAGRAPH_COMMANDS: {
    LEFT: "justifyleft",
    CENTER: "justifycenter",
    RIGHT: "justifyright",
    BLOCK: "justifyblock",
  },
  PARAGRAPH_STYLE_BY_COMMAND: {
    justifyleft: "text-align: left;",
    justifycenter: "text-align: center;",
    justifyright: "text-align: right;",
    justifyblock: "text-align: justify;",
  },
  PARAGRAPH_STYLES_GROUP: [
    "text-align: left;",
    "text-align: center;",
    "text-align: right;",
    "text-align: justify;",
  ],
  LIST_COMMANDS: {
    bulletedlist: "bulletedlist",
    numberedlist: "numberedlist",
  },
  LIST_COMMANDS_GROUP: ["bulletedlist", "numberedlist"],
  INDENT_COMMANDS: {
    outdent: "outdent",
    indent: "indent",
  },
  INDENT_COMMANDS_GROUP: ["outdent", "indent"],
  UNDO_COMMAND: "undo",
  TRACKING_CHANGES_TAGS_GROUP: ["ins", "del"],
  PROJECT_SOURCE_TYPE: {
    internal: "FPP",
    external: "TPP",
    insights: "INS",
    search: "SRH",
    filing: "FILING",
  },
  FEATURES_TOGGLE: {
    FPP: "FPP",
    IMPORT_NEW_VERSION: "IMPORT_NEW_VERSION",
    VERSION_HISTORY: "VERSION_HISTORY",
    APPROVAL_CENTER: "APPROVAL_CENTER",
    PLAYBOOKS: "PLAYBOOKS",
    PLAYBOOKS_READONLY: "PLAYBOOKS_READONLY",
    GET_APPROVAL: "GET_APPROVAL",
    CHAT: "CHAT",
    ASSEMBLY_QUESTIONS: "ASSEMBLY_QUESTIONS",
    SEND_TO_COUNTERPARTY: "SEND_TO_COUNTERPARTY",
    SEND_FOR_SIGNATURE: "SEND_FOR_SIGNATURE",
    DASHBOARDS: "DASHBOARDS",
    REQUESTS: "REQUESTS",
    TICKETS: "TICKETS",
    EXTERNAL_ID: "EXTERNAL_ID",
    REVIEW_UPLOAD_SOURCETYPE: "REVIEW_UPLOAD_SOURCETYPE",
    INTERCOM: "INTERCOM",
    HUBSPOT: "HUBSPOT",
    COUNTERPARTIES: "COUNTERPARTIES",
  },
  GA_LOCAL_STORAGE_KEY: "ga:clientId",
  USER_MANAGEMENT_TYPE: {
    PROPRIETARY: "PROPRIETARY",
    SCIM: "SCIM",
  },
  ACCOUNT_TYPE: {
    PROPRIETARY: "PROPRIETARY",
    FEDERATED: "FEDERATED",
  },
  DEFAULT_LOGIN_URL: "/login-legacy", // keeping a legacy login page around post-Auth0 impl in case it's needed, but it's not expected to be used
  ACTION_ICON_MAP: {
    ACCEPT: "fa-check",
    REJECT: "fa-times",
    REMOVE: "fa-times",
    GET_APPROVAL: "fa-share",
    CANCEL_APPROVAL: "highlight_off",
    APPROVE: "thumb_up",
    DENY: "thumb_down",
    OPEN: "create",
    REVISE: "create",
    REVERT: "undo",
    ADD: "add",
    PARTIAL_ACCEPT: "fa-check",
    PARTIAL_REJECT: "fa-times",
    PARTIAL_REVISE: "create",
    NEW_VERSION: "file_upload",
    EXPORT: "file_download",
    FINAL: "far-check-circle",
    VIEW: "far-clone",
    ADD_CLAUSE: "add",
    REMOVE_CLAUSE: "fa-times",
    DONT_ADD: "fa-times",
    ASSIGN_OWNER: "fas-user-circle",
    NEGOTIATE: "far-check-circle",
    FINALIZE: "far-check-circle",
    DONE: "far-check-circle",
  },
  ACTION_MAP_STATUS_LABEL: {
    ACCEPT: "clause-card.accepted-status",
    REJECT: "clause-card.rejected-status",
    REMOVE: "clause-card.removed-status",
    REVISE: "clause-card.revised-status",
    ADD: "clause-card.added-status",
    ADD_CLAUSE: "clause-card.added-status",
    REMOVE_CLAUSE: "clause-card.removed-status",
    OPEN: "clause-card.reopen-status",
    REVERT: "clause-card.reverted-status",
    DONT_ADD: "clause-card.dont_added-status",
    APPROVE: "clause-card.approved-status",
    DENY: "clause-card.denied-status",
    GET_APPROVAL: "clause-card.getting-approval-status",
    CANCEL_APPROVAL: "clause-card.canceling-approval-status",
    NEGOTIATE: "clause-card.in-negotation-status",
    FINALIZE: "clause-card.final-status",
    DONE: "globals.done",
  },
  STATUS_ICON_MAP: {
    OPEN: "warning-circle-icon.svg",
    ACCEPTED: "far-check-circle",
    APPROVED: "thumb_up",
    APPROVED_WITH_EDIT: "thumb_up",
    DENIED: "thumb_down",
    REJECTED: "far-times-circle",
    REMOVED: "far-times-circle",
    ADDED: "add",
    DONT_ADDED: "fa-times",
    AWAITING_APPROVAL: "far-clock",
    AWAITING_APPROVAL_IN_QUEUE: "far-clock",
    REVISED: "edit-circle-icon.svg",
    MISSING: "help_outline",
    IN_NEGOTIATION: "far-check-circle",
    FINAL: "far-check-circle",
  },
  CLAUSE_SECTION_MAP: {
    OPEN_PLAYBOOK: "OPEN_PLAYBOOK",
    OPEN_OTHER: "OPEN_OTHER",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    DENIED: "DENIED",
    PROCESSED: "PROCESSED",
    MISSING: "MISSING",
  },
  PAST_EXAMPLE_STATUS: {
    PRIVATE: "PRIVATE",
    VISIBLE: "VISIBLE",
  },
  TOOLBOX_TAB_ID_MAP: {
    STANDARD: "standard",
    ISSUES: "issues",
    FALLBACKS: "fallbacks",
    PAST_EXAMPLES: "past-examples",
    HISTORY: "history",
    GUIDANCE: "guidance",
    CHAT: "chat",
  },
  BALLOON_TOOLBAR_COMMANDS_GROUP: [
    "accept",
    "reject",
    "acceptchange",
    "rejectchange",
    "revert",
    "approve",
    "done",
    "close",
    "viewcomment",
    "getApproval",
    "cancelapproval",
    "compare",
    "edit",
    "deny",
    "addComment",
  ],
  NAVIGATION_ACTION_MAP: {
    PREVIOUS: "PREVIOUS",
    NEXT: "NEXT",
    START: "START",
  },

  CLAUSE_SORT_GROUP: {
    POSITION: "POSITION",
    RISK: "RISK",
    OPEN: "OPEN",
    PROCESSED: "PROCESSED",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    DENIED: "DENIED",
  },
  CLAUSE_TYPE: {
    MISSING: "MISSING",
    UNKNOWN: "UNKNOWN",
  },
  DOCUMENT_ACTION_MAP: {
    NEW_VERSION: "NEW_VERSION",
    EXPORT: "EXPORT",
    ASSIGN_OWNER: "ASSIGN_OWNER",
    FINAL: "FINAL",
    CLOSE_VERSION: "CLOSE_VERSION",
  },
  EDITOR_ADAPTER_MAP: {
    APPROVAL: "APPROVAL",
    CLAUSE: "CLAUSE",
    COMMENT: "COMMENT",
    COMMON: "COMMON",
    CONTEXT_MENU: "CONTEXT_MENU",
    EVENT: "EVENT",
    EVENT_HTML: "EVENT_HTML",
    TOOLBAR: "TOOLBAR",
    TRACKING_CHANGE: "TRACKING_CHANGE",
    TRANSITION: "TRANSITION",
  },
  EDITOR_TYPE_MAP: {
    FPP: "FPP",
    TPP: "TPP",
    HISTORY: "HISTORY",
    APPROVAL: "APPROVAL",
    TPP_APPROVAL: "TPP_APPROVAL",
    FPP_VERSIONS: "FPP_VERSIONS",
    EXTERNAL_APPROVAL: "EXTERNAL_APPROVAL",
  },
  APPROVALS_TABS: {
    AWAITING_APPROVAL: "awaiting-approval",
    PROCESSED: "processed",
  },
  APPROVAL_STATUS: {
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    AWAITING_APPROVAL_IN_QUEUE: "AWAITING_APPROVAL_IN_QUEUE",
    APPROVED: "APPROVED",
    APPROVED_WITH_EDIT: "APPROVED_WITH_EDIT",
    DENIED: "DENIED",
  },
  EDITOR_EVENTS_MAP: {
    INSTANCE_READY: "instanceReady",
    CHANGE: "change",
    BLUR: "blur",
    ADD_COMMENT: "addComment",
    TOGGLE_CHANGES: "toggleChanges",
    TOGGLE_COMMENTS: "toggleComments",
    TOGGLE_HIGHLIGHTS: "toggleHighlights",
    ACCEPT: "accept",
    REJECT: "reject",
    ACCEPT_INDIVIDUAL: "acceptChange",
    REJECT_INDIVIDUAL: "rejectChange",
    REVERT: "revert",
    GET_APPROVAL: "getApproval",
    DONE: "done",
    CLOSE: "close",
    VIEW_COMMENT: "viewComment",
    CANCEL_APPROVAL: "cancelApproval",
    KEY: "key",
    BEFORE_COMMAND_EXEC: "beforeCommandExec",
    AFTER_COMMAND_EXEC: "afterCommandExec",
    COMPARE: "compare",
    APPROVE: "approve",
    DENY: "deny",
    EDIT: "edit",
    PROPS_CHANGE: "propsChange",
    ACTION_CARD: "actionCard",
    ACTION_TOOLBAR: "actionToolbar",
    ELEMENT_CHANGED: "elementChanged",
    LOADED: "loaded",
  },
  HTML_EVENTS_MAP: {
    MOUSE_OVER: "mouseover",
    MOUSE_WHEEL: "mousewheel",
    CLICK: "click",
    CONTEXT_MENU: "contextMenu",
    KEY_DOWN: "keydown",
    KEY_UP: "keyup",
  },
  EDITOR_CURSOR_POSITION: {
    AFTER_START: 1,
    BEFORE_END: 2,
    BEFORE_START: 3,
    AFTER_END: 4,
  },
  EDITOR_ACTION_MAP: {
    SHOW_COMMENT_MODAL: "SHOW_COMMENT_MODAL",
    HIDE_COMMENT_MODAL: "HIDE_COMMENT_MODAL",
    TOGGLE_CHANGES: "TOGGLE_CHANGES",
    TOGGLE_COMMENTS: "TOGGLE_COMMENTS",
    ADD_COMMENT: "ADD_COMMENT",
    SHOW_APPROVAL_MODAL: "SHOW_APPROVAL_MODAL",
    SHOW_DENIAL_MODAL: "SHOW_DENIAL_MODAL",
    SHOW_GET_APPROVAL_MODAL: "SHOW_GET_APPROVAL_MODAL",
    SHOW_CANCEL_APPROVAL_MODAL: "SHOW_CANCEL_APPROVAL_MODAL",
    VIEW_APPROVALS: "VIEW_APPROVALS",
    EDIT: "EDIT",
  },
  ARROW_KEYS: [37, 38, 39, 40],
  HORIZONTAL_ARROW_KEYS: [37, 39],
  DELETE_KEYS: [8, 46],
  BACKSPACE_KEY: 8,
  DEL_KEY: 46,
  LEFT_KEY: 37,
  RIGHT_KEY: 39,
  DELETE_CLAUSE_CLASS: "deleted-but-revertable",
  UPDATE_MODEL_TIME: 5,
  PLAYBOOKS_STATUS_MAP: {
    ARCHIVED: "ARCHIVED",
    PUBLISHED: "PUBLISHED",
    DRAFT: "DRAFT",
    PROCESSING: "PROCESSING",
    PUBLISHING: "PUBLISHING",
    UNCONFIRMED: "UNCONFIRMED",
    FAILED: "FAILED",
  },
  PLAYBOOK_PAPER_TYPES: {
    COMPANY_PAPER: "COMPANY_PAPER",
    COUNTERPARTY_PAPER: "COUNTERPARTY_PAPER",
  },
  INSIGHTS_CLAUSE_TABS: {
    SUMMARY: "summary",
    EXAMPLES: "examples",
  },
  INSIGHTS_CLAUSE_PART_INDEX: {
    standard: 1,
    fallback: 2,
    other: 3,
  },
  INSIGHTS_CLAUSE_PART_MAP: {
    standard: "standard",
    fallback: "fallback",
    other: "other",
  },
  INSIGHTS_DOCUMENT_METADATA_MAP: {
    NEGOTIATED_BY: "negotiatedBy",
    COUNTER_PARTY: "counterParty",
    LAW_FIRM: "lawFirm",
    INDUSTRY: "industry",
  },
  CHAT_MESSAGE_MAX_LENGTH: 1000,
  CLAUSE_STRUCTURE_TYPE_MAP: {
    SECTION: "SECTION",
    PARAGRAPH: "PARAGRAPH",
    LIST: "LIST",
  },
  ISSUE_LEVEL: {
    ACCEPTABLE: "ACCEPTABLE",
    UNACCEPTABLE: "UNACCEPTABLE",
  },
  PLAYBOOK_LIST_TABS: {
    COMPANY_PAPER: "company_paper",
    COUNTERPARTY: "counterparty",
    ALL: "all",
  },
  INSIGHTS_ADMIN_TABS: {
    FRICTION_THRESHOLDS: "frictions_thresholds",
    PLAYBOOK_HISTORY: "playbook_history",
    METADATA: "metadata",
    ASSEMBLY_QUESTIONS: "assembly_questions",
    TEMPLATE: "template",
    SIGNATURE: "signature",
  },
  COMPANY_ADMIN_TABS: {
    USERS: "users",
    ROLES_AND_GROUPS: "roles-groups",
    COMPANY_PROFILE: "company-profile",
    METADATA: "metadata",
    INTEGRATIONS: "integrations",
    ORDER_TABLES: "order-tables",
    CUSTOM_FIELDS: "custom-fields",
    TICKET_TYPES: "ticket-types",
    SETTINGS: "settings",
    BACKUPS: "backups",
    SIFTER_SETTINGS: "sifter-settings",
  },
  SETTINGS_TABS: {
    USER_PROFILE: "user-profile",
  },
  COMPANY_SETTINGS_GROUP: {
    SEARCH: "search",
    REMINDERS: "reminders",
    SCHEDULER: "scheduler",
    CONTRACT: "contract",
  },
  COMMENT_CARD_HEIGHT: 56, // in pixels
  DEFAULT_NO_VALUE: "⸺",
  CLAUSE_FILTER: {
    ALL: "ALL",
    PROCESSED: "PROCESSED",
    UNPROCESSED: "UNPROCESSED",
  },
  EDITOR_USER_STYLE: "ice-cts-0", // akorda user always has style 0
  NEGOTATION_STATUS_MAP: {
    UNCHANGED: "UNCHANGED",
    IN_NEGOTIATION: "IN_NEGOTIATION",
    FINAL: "FINAL",
    FOR_SIGNATURE: "FOR_SIGNATURE",
    AWAITING_SIGNATURE: "AWAITING_SIGNATURE",
    EXECUTED: "EXECUTED",
    CANCELED: "CANCELED",
  },
  TURN_STATUS_MAP: {
    OPEN: "OPEN",
    DONE: "DONE",
  },
  APPROVAL_STATUS_MAP: {
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    AWAITING_APPROVAL_IN_QUEUE: "AWAITING_APPROVAL_IN_QUEUE",
    APPROVED: "APPROVED",
    APPROVED_WITH_EDIT: "APPROVED_WITH_EDIT",
    DENIED: "DENIED",
  },
  MIME_TYPES_MAP: {
    DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx",
    PDF: "application/pdf,.pdf",
    MSG: "application/vnd.ms-outlook,.msg",
    PPT: "application/vnd.ms-powerpoint,.ppt",
    PPTX: "application/vnd.openxmlformats-officedocument.presentationml.presentation,.ppt",
    XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx",
    EML: "message/rfc822,.eml",
    TXT: "text/plain,.txt",
  },
  MIME_TYPES_ICON_MAP: {
    DOCX: "fad-file-word",
    PDF: "fad-file-pdf",
    MSG: "fad-envelope-open-text",
    FILE: "fad-file-alt",
    XLSX: "fad-file-excel",
    PPT: "fad-file-powerpoint",
    PPTX: "fad-file-powerpoint",
    EML: "fad-envelope-open-text",
    TXT: "fad-file-alt",
    IMAGE: "fad-file-image",
    ARCHIVE: "fad-file-archive",
  },
  REVIEW_FILTERS: {
    displayFields: [
      "projectTitle",
      "documentType",
      "negotiationStatus",
      "createdDate",
      "updatedDate",
      "createdBy.fullName",
      "owner.fullName",
    ],
  },
  REVIEW_APPROVER_TYPE: {
    INTERNAL: "INTERNAL",
    EXTERNAL: "EXTERNAL",
  },
  STANDARD_PAGE_SIZE: 20,
  REDIRECT_URL_BLACKLIST: ["/login", "/logout", "/federated-login"],
  MIN_EXAMPLES_FOR_FALLBACK_PROPOSALS: 10,
  RENEWAL_TERMINATION_REMINDERS: {
    NOTICE_REQUIRED_DAYS: 30,
    ITERATIONS: 1,
  },
};

export default constants;
