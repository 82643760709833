export enum PlaybookViewTypes {
  List = "list",
  Cards = "cards",
}

export enum Preferences {
  CounterpartyFiledDocumentColumns = "counterparty-filed-documents-columns",
  MetadataCollapsedGroups = "metadata-collapsed-groups",
  PlaybookLibraryColumns = "playbook-library-columns",
  PlaybookListViewType = "playbook-list-view-type",
  PlaybookMetadataCollapsedGroups = "playbook-metadata-collapsed-groups",
  PlaybookUnmatchedColumns = "playbook-unmatched-result-columns",
  SearchFilterCollapsedGroups = "search-filters-collapsed-groups",
  SearchResultColumns = "search-result-columns",
  TicketResultColumns = "ticket-result-columns",
  TicketView = "ticket-view-type",
  SearchSourceTypeFilter = "search-source-type-filter",
}
